import format from 'date-fns/format'
import { CreditCard, Receipt, Wallet } from 'lucide-react'
import React from 'react'
import { DropdownContainer, DropdownContent, PaymentDetails } from '../styles'

interface PaymentDropdownProps {
  paymentInfo: any[]
  isOpen: boolean
  onClick: () => void
}

const getPaymentMethodName = (method: string) => {
  switch (method) {
    case 'credit_card':
      return 'Cartão de crédito'
    case 'pix':
      return 'Pix'
    case 'boleto':
      return 'Boleto'
    default:
      return method
  }
}

const singleMethods = [
  {
    id: 'credit_card',
    title: 'Cartão de Crédito',
    icon: <CreditCard size={24} />
  },
  {
    id: 'pix',
    title: 'Pix',
    icon: <Wallet size={24} />
  },
  {
    id: 'boleto',
    title: 'Boleto Bancário',
    icon: <Receipt size={24} />
  }
]

const PaymentDropdown: React.FC<PaymentDropdownProps> = ({ paymentInfo, isOpen, onClick }) => {
  if (!paymentInfo || !paymentInfo.length) return null

  const paidPayment = paymentInfo.find((payment) => payment.status === 'paid')
  if (!paidPayment) return null

  const { payments_orders } = paidPayment
  const { charges } = payments_orders

  return (
    <DropdownContainer>
      {isOpen && (
        <DropdownContent>
          {charges.map(
            (charge: any) =>
              charge.status === 'paid' && (
                <PaymentDetails key={charge.id}>
                  {charge.transactions
                    .filter((transaction: any) => transaction.external_id === charge.last_transaction_external_id)
                    .map((transaction: any) => (
                      <div key={transaction.id}>
                        <div className="icon-container">
                          {singleMethods.find((method) => method.id === charge.payment_method)?.icon}
                          <p>
                            <strong>{getPaymentMethodName(charge.payment_method)}</strong>
                          </p>
                        </div>

                        {charge.payment_method === 'credit_card' && (
                          <p style={{ marginBottom: '3px' }}>
                            {transaction.payments_methods[0].brand} {transaction.payments_methods[0].first_six_digits}{' '}
                            **** {transaction.payments_methods[0].last_four_digits}
                          </p>
                        )}
                        <p>Pago em {format(new Date(charge.paid_at), 'dd/MM/yyyy')}</p>
                        <div style={{ color: '#6B7280', marginTop: 4 }}>
                          {charge.payment_method === 'credit_card' && <>{transaction.installments}x • </>}
                          R$ {(charge.amount / 100).toFixed(2)}
                        </div>
                      </div>
                    ))}
                </PaymentDetails>
              )
          )}
        </DropdownContent>
      )}
    </DropdownContainer>
  )
}

export default PaymentDropdown
