import React from 'react'
import { GENDER_LIST } from 'utils/constants'
import { formatDate } from 'utils/formatters'
import InputMask from 'react-input-mask'
import * as Style from './styles'

type Customer = {
  name?: string
  email?: string
  cpf?: string
  birthday?: string
  gender?: string
  phone?: string
  phone_residencial?: string
}
interface FormCustomerProps {
  isMobile: boolean
  changeCustomer: ({ fieldToChange, newValue }: { fieldToChange: string; newValue: string }) => void
  customer: Customer
}

function FormCustomer({ changeCustomer, customer }: FormCustomerProps) {
  function onlyNumbers(input: string) {
    return input.replace(/[^0-9]/g, '')
  }

  const validateDate = (date) => {
    const [day, month, year] = date.split('/').map(Number);
    const dateObj = new Date(year, month - 1, day);
    return (
      dateObj.getFullYear() === year &&
      dateObj.getMonth() === month - 1 &&
      dateObj.getDate() === day &&
      dateObj <= new Date()
    );
  };

  const handleBirthdayChange = (event) => {
    const newBirthday = event.target.value;
    changeCustomer({ fieldToChange: 'birthday', newValue: newBirthday });
    if (newBirthday.length === 10 && validateDate(newBirthday)) {
      const [day, month, year] = newBirthday.split('/');
      const formattedDate = `${year}-${month}-${day}`;
      changeCustomer({ fieldToChange: 'birthday', newValue: formattedDate });
    }
  };

  const formatDateToInput = (isoDate) => {
    const [year, month, day] = isoDate.split('-');
    return `${day}/${month}/${year}`;
  };
  
  

  return (
    <Style.FormContainer>
      <div className="input-groups">
        <div>
          <span>Nome</span>
          <input
            type="text"
            id="name"
            disabled
            value={customer?.name}
            onChange={(event) => changeCustomer({ fieldToChange: 'name', newValue: event.target.value })}
            maxLength={9}
          />
        </div>
        <div>
          <span>Email</span>
          <input
            type="text"
            id="email"
            disabled
            value={customer?.email}
            onChange={(event) => changeCustomer({ fieldToChange: 'email', newValue: event.target.value })}
          />
        </div>
        <div>
          <span>CPF</span>
          <InputMask
            mask="999.999.999-99"
            type="text"
            id="cpf"
            disabled
            value={customer?.cpf}
            onChange={(event) => {
              const onlyNumValue = onlyNumbers(event.target.value)
              changeCustomer({ fieldToChange: 'cpf', newValue: onlyNumValue })
            }}
          />
        </div>
      </div>

      <div className="input-groups">
        <div>
          <span>Data de Aniversário</span>
          <InputMask
            mask="99/99/9999"
            type="text"
            id="birthday"
            value={customer?.birthday ? formatDateToInput(customer.birthday) : ''}
            onChange={handleBirthdayChange}
          />
        </div>
        <div>
          <span>Celular</span>
          <InputMask
            mask="(99)99999-9999"
            type="text"
            id="phone"
            required
            value={customer?.phone}
            onChange={(event) => {
              const onlyNumValue = onlyNumbers(event.target.value)
              changeCustomer({ fieldToChange: 'phone', newValue: onlyNumValue })
            }}
          />
        </div>
      </div>

      <div className="input-groups">
        <div>
          <span>telefone</span>
          <InputMask
            mask="(99)99999-9999"
            type="text"
            id="phone_residencial"
            required
            value={customer?.phone_residencial}
            onChange={(event) => {
              const onlyNumValue = onlyNumbers(event.target.value)
              changeCustomer({ fieldToChange: 'phone_residencial', newValue: onlyNumValue })
            }}
          />

        </div>
        <div>
          <span>Genêro/Sexo</span>
          <select
            required
            value={customer?.gender}
            onChange={(event) => changeCustomer({ fieldToChange: 'gender', newValue: event.target.value })}>
            <option>Selecione um</option>
            {GENDER_LIST.map((gender) => (
              <option key={gender.label} value={gender.value}>{gender.label}</option>
            ))}
          </select>
        </div>
      </div>
    </Style.FormContainer>
  )
}

export default FormCustomer
