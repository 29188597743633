import React, { useEffect, useState } from 'react'
import useMediaQuery from 'hooks/useMediaQuery'
import { noop } from 'lodash'
import { MOBILE_WIDTH } from 'utils/constants'
import * as Style from './styles'
import FormCustomer from './FormCustomer'

export interface CustomerProps {
  name?: string
  email?: string
  cpf?: string
  birthday?: string
  gender?: string
  phone?: string
  phone_residencial?: string
}

interface CustomerType {
  customer: any
  setCostumer: any
}
export function Customer({ customer, setCostumer }: CustomerType) {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const changeCustomerData = ({ fieldToChange, newValue }: any) => {
    const newCustomerData = { ...customer, [fieldToChange]: newValue }
    setCostumer(newCustomerData)
  }

  useEffect(() => {
    setCostumer({
      name: customer?.name,
      email: customer?.email,
      cpf: customer?.cpf,
      birthday: customer?.birthday,
      gender: customer?.gender,
      phone: customer?.phone,
      phone_residencial: customer?.phone_residencial
    })
  }, [])

  return (
    <Style.Wrapper isMobile={isMobile}>
      <Style.Container isMobile={isMobile}>
        <Style.Title>
          Dados pessoais
          <p>*Confirme seus dados para a entrega</p>
        </Style.Title>
        <FormCustomer isMobile={isMobile} customer={customer} changeCustomer={changeCustomerData} />
        <></>
      </Style.Container>
    </Style.Wrapper>
  )
}
