import { SyncOutlined } from '@ant-design/icons'
import { Select } from 'antd'
import LoadingContainer from 'components/LoadingContainer'
import { usePrepareCart } from 'hooks/usePrepareCart'
import useQueryString from 'hooks/useQueryString'
import { useEffect, useState } from 'react'
import { RiErrorWarningFill } from 'react-icons/ri'
import { useHistory } from 'react-router'
import api from 'services/api'
import { formatCard, formatReal } from 'utils/formatters'
import { generateUniqueId } from 'utils/validators'
import StatusModal from '../StatusModal'
import * as Style from './styles'

const { Option } = Select

interface Props {
  isValidateSubmit?: boolean
  finishOrderByPaymentMethod: any
  loadingContainer: boolean
}
export interface CreditCard {
  card_name?: string
  card_number?: string
  card_month_validate?: string
  card_year_validate?: string
  card_cvv: string
  installments?: number
  birth_date?: string
  cpf?: string
}
export type CreditCardFields =
  | 'card_name'
  | 'card_number'
  | 'card_month_validate'
  | 'card_year_validate'
  | 'card_cvv'
  | 'installments'
  | 'birth_date'
  | 'cpf'

const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']

export function CreditCart({ finishOrderByPaymentMethod, isValidateSubmit, loadingContainer }: Props) {
  const { lastOrderCreated } = usePrepareCart()
  const [error, setError] = useState(false)
  const [installments, setInstallments] = useState<any>([])
  const [loadingInstallments, setLoadingInstallments] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const originCheck = ['acolhimento-cannect', 'B2C', 'COD B', 'Recipe']
  const isOriginMatched = originCheck.some((origin) => origin === lastOrderCreated.originCheckout)
  const IsYear = new Date()
  const defaultYear = IsYear.getFullYear()
  const [creditCard, setCreditCard] = useState<CreditCard>({
    installments: 1,
    card_month_validate: '01',
    card_year_validate: defaultYear.toString(),
    card_cvv: ''
  })

  const { filters } = useQueryString()
  const history = useHistory()
  const withFilter = filters?.cart_id
  const years: any[] = getYears()

  const handlePaymentCreditCard = async () => {
    try {
      const { paymentInfos, error } = await finishOrderByPaymentMethod({ type: 'credit_card', creditCard })
      const isPublicUrlStatus = withFilter ? `/analise-cannect?cart_id=${withFilter}` : await getCartID()
      if (paymentInfos?.status === 'paid') {
        history.push(isPublicUrlStatus)
        localStorage.removeItem('@CANNECT:ORDER_CHECKOUT_CART_ID')
      } else if (paymentInfos?.status === 'refused') {
        setError(true)
      } else {
        history.push(isPublicUrlStatus)
        localStorage.removeItem('@CANNECT:ORDER_CHECKOUT_CART_ID')
      }
    } catch (e) {
      setError(true)
    }
  }

  async function getCartID() {
    const storedCartID = localStorage.getItem('@CANNECT:ORDER_CHECKOUT_CART_ID')
    if (storedCartID && storedCartID !== 'null' && storedCartID !== null) {
      const data = await api.get(`/order/${storedCartID}`)
      const cartID = data.data?.order?.uuid
      const isPublicUrl = `/analise-cannect?cart_id=${cartID}`
      return isPublicUrl
    }
    const isPublicUrl = '/conta?pagina=pedidos-todos'
    return isPublicUrl
  }

  function changeCreditCardInfo(fieldToChange: CreditCardFields, newValue: string) {
    setCreditCard({
      ...creditCard,
      [fieldToChange]: newValue
    })
  }

  function getYears() {
    const years = new Set()
    const startYear = new Date().getFullYear()

    // eslint-disable-next-line no-plusplus
    for (let i = startYear; i <= 2050; i++) {
      years.add(i)
    }
    const sortedYears = Array.from(years).sort()
    return sortedYears
  }

  async function reloadInstallments() {
    setSpinner(true)
    await getInstallments()
  }

  async function getInstallments() {
    setLoadingInstallments(true)
    const url = withFilter
      ? `/payments/installments_public/${lastOrderCreated?.id}`
      : `/payments/installments/${lastOrderCreated?.items[0]?.order_id || lastOrderCreated?.id}`
    try {
      const { data } = await api.get(`${url}`)
      setLoadingInstallments(false)
      setSpinner(false)
      setInstallments(data?.installments)
      return data
    } catch (error) {
      setSpinner(false)
      setLoadingInstallments(false)
      return error
    }
  }

  useEffect(() => {
    changeCreditCardInfo('installments', '1')
  }, [])

  useEffect(() => {
    if (lastOrderCreated?.items[0]?.order_id || lastOrderCreated?.id) {
      getYears()
      getInstallments()
    }
  }, [lastOrderCreated])

  return (
    <Style.Container>
      {loadingContainer && <LoadingContainer loading />}
      {!loadingContainer && (
        <>
          <Style.FormContainer>
            <div className="input-column">
              <span>Nome no cartão</span>
              <input
                type="text"
                id="card_name"
                required
                value={creditCard?.card_name}
                onChange={(e) => changeCreditCardInfo('card_name', e.target.value)}
              />

              <span>N° do cartão</span>
              <input
                type="text"
                id="card_number"
                required
                maxLength={19}
                value={formatCard(creditCard?.card_number)}
                onChange={(e) => changeCreditCardInfo('card_number', e.target.value)}
              />
            </div>

            <div className="input-groups">
              <div>
                <span>Mes</span>
                <select
                  required
                  value={creditCard?.card_month_validate}
                  onChange={(e) => changeCreditCardInfo('card_month_validate', e.target.value)}>
                  {months.map((month) => (
                    <option key={generateUniqueId(month)} value={month}>
                      {month}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <span>Ano</span>
                <select
                  required
                  value={creditCard?.card_year_validate}
                  onChange={(e) => changeCreditCardInfo('card_year_validate', e.target.value)}>
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year.toString().substr(-2)}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <span>CVV</span>
                <input
                  type="text"
                  id="card_cvv"
                  minLength={3}
                  maxLength={4}
                  required
                  value={creditCard?.card_cvv}
                  onChange={(e) => changeCreditCardInfo('card_cvv', e.target.value)}
                />
              </div>
            </div>
            <div className="input-column">
              <div style={{ marginBottom: '5px' }}>
                <span>Parcelamento</span>
                <SyncOutlined spin={spinner} style={{ marginLeft: '10px' }} onClick={() => reloadInstallments()} />
              </div>

              <Select
                placeholder="Selecione a quantidade de parcelas."
                size="large"
                virtual={false}
                loading={loadingInstallments}
                onChange={(installment) => changeCreditCardInfo('installments', installment)}>
                {installments.map((installment: any) => (
                  <Option key={generateUniqueId(installment?.id)} value={installment?.id}>
                    <Style.OptionInstallment>
                      <p className="values_installment">
                        {`${installment?.installment} de ${formatReal(installment?.amount)}`}
                      </p>

                      {installment?.fees === '(sem juros)' ? (
                        <p className="total_installment not_installment">Sem juros</p>
                      ) : (
                        <p className="total_installment">{formatReal(installment?.total)}</p>
                      )}
                    </Style.OptionInstallment>
                  </Option>
                ))}
              </Select>
            </div>
          </Style.FormContainer>
          <Style.ButtonContainer>
            <Style.BtnFinishOrder
              onClick={handlePaymentCreditCard}
              disabled={isValidateSubmit || creditCard.card_cvv.length < 3}>
              Finalizar pedido
            </Style.BtnFinishOrder>
          </Style.ButtonContainer>
        </>
      )}
      <StatusModal
        onClickButton={() => setError(false)}
        variant="error"
        icon={<RiErrorWarningFill />}
        textButton="Tentar Enviar Novamente"
        isOpen={error}
        onClose={() => setError(false)}
        title="Houve um erro ao processar seu pagamento"
        text="por favor revise seus dados e tente novamente"
      />
    </Style.Container>
  )
}
